// eslint-disable-next-line import/prefer-default-export
export function applyDrag(arr, dragResult) {
  const result = {
    changed: false,
    items: arr,
  };

  const { removedIndex, addedIndex, payload } = dragResult;
  if (
    (removedIndex === null && addedIndex === null)
    || removedIndex === addedIndex
  ) {
    return result;
  }

  result.items = [...result.items];
  result.changed = true;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    [itemToAdd] = result.items.splice(removedIndex, 1);
  }

  if (addedIndex !== null) {
    result.items.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}
