<template>
  <div class="medical-report-header">
    <div class="tile tile-centered">
      <div class="tile-icon">
        <figure class="avatar">
          <img :src="patientImage" :alt="patient.name">
        </figure>
      </div>
      <div class="tile-content" v-if="appointment">
        <div class="h6">
          {{ patient.name }}
          <small>({{ patient.birthDate | date }}</small>
          <small class="text-gray"> - </small>
          <small>{{ patient.birthDate | dateOld('short') }})</small>
        </div>
        <div class="mt-1">
          <span class="text-info" v-for="(item, i) in expenses" :key="i">
            <span>{{ item.code | tuss }} - {{ item.name }}</span>
            <span v-if="i < expenses.length - 1">, </span>
          </span>
        </div>
      </div>
      <div class="tile-action text-right">
        <label class="text-gray" for="template-id">Modelo de Laudo</label>
        <select
          id="template-id"
          class="form-select"
          v-model="templateId"
          :disabled="!canEdit || isExternal"
          @change="changeTemplate"
        >
          <option
            v-for="item in templates"
            :value="item.id"
            :key="item.id"
          >{{ item.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      templateId: {
        type: String,
      },
      appointment: {
        type: Object,
      },
    },
    canEdit: {
      type: Boolean,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: this.data.id,
      templates: [],
      templateId: this.data.templateId,
    };
  },
  mounted() {
    this.loadTemplates();
  },
  watch: {
    'data.templateId': function templateId() {
      this.templateId = this.data.templateId;
    },
  },
  computed: {
    appointment() {
      if (this.data && this.data.appointment) {
        return this.data.appointment;
      }
      return null;
    },
    patient() {
      if (this.appointment && this.appointment.patient) {
        return this.appointment.patient;
      }
      return null;
    },
    patientImage() {
      if (!this.patient || !this.patient.gender) {
        return null;
      }

      return this.patient.imageUrl
        ? `${this.form.appointment.patient.imageUrl}?w=200&h=200`
        : `//static.stenci.pro/images/no-image-${this.patient.gender}.png`;
    },
    expenses() {
      return this.appointment
        ? this.appointment.expenses
        : [];
    },
  },
  methods: {
    changeTemplate() {
      const template = this.templates.find(({ id }) => id === this.templateId);
      this.$emit('change-template', template);
    },
    loadTemplates() {
      const params = {
        type: 'medical_report',
      };

      return this.$httpX.get('/templates', { params })
        .then(({ data }) => {
          this.templates = data.items;
          if (this.id && !this.templateId) {
            this.templateId = data.items[0].id;
            this.changeTemplate();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.medical-report-header {
  align-items: center;
  display: flex;
  .tile {
    flex: 1;
    padding: 0 $layout-spacing-lg;
  }
  .avatar {
    height: $unit-12;
    width: $unit-12;
  }
}
</style>
