<template>
  <div class="print-layout-preview">
    <div class="print-layout print-layout-main">
      <div class="print-layout-header print-layout-el">Cabeçalho</div>
      <div class="print-layout-body" :class="{ 'report-compact': compact }">
        <div class="report-text">
          <div class="print-layout-el" v-for="i in 20" :key="i"></div>
        </div>
        <div class="report-images" v-if="compact">
          <div class="print-layout-el" v-for="i in 6" :key="i"></div>
        </div>
      </div>
      <div class="print-layout-footer print-layout-el">Rodapé</div>
    </div>

    <div class="print-layout print-layout-images" :class="{ disabled: compact }">
      <div class="print-layout-header print-layout-el">Cabeçalho</div>
      <div class="print-layout-body" :class="`images-col-${columns}`">
        <div class="print-layout-el" v-for="i in tiles" :key="i"></div>
      </div>
      <div class="print-layout-footer print-layout-el">Rodapé</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    imageCols: {
      type: Number,
      default: 0,
    },
    imagesCount: {
      type: Number,
      default: 0,
    },
    maxCols: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    columnsByCount() {
      const cols = Math.round(Math.sqrt(this.imagesCount));
      return cols < this.maxCols ? cols : this.maxCols;
    },
    columns() {
      return this.imageCols ? this.imageCols : this.columnsByCount;
    },
    tiles() {
      return this.columns * (this.columns + 1);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
@import 'src/assets/scss/colors';

.print-layout-preview {
  $width: 10rem;
  $gap: $layout-spacing-lg;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: $gap;
  max-width: $width + $width + $gap;

  .print-layout {
    border: $border-width solid $border-color-dark;
    border-radius: $border-radius;
    display: grid;
    grid-column-gap: $layout-spacing;
    grid-row-gap: $layout-spacing;
    grid-template-rows: 1fr 8fr 1fr;
    height: 14.14rem;
    padding: $layout-spacing;
    width: $width;
    &.disabled {
      opacity: .2;
    }
  }

  .print-layout-el {
    align-items: center;
    background: $border-color-dark;
    color: $body-font-color;
    display: flex;
    font-size: 80%;
    font-weight: 600;
    justify-content: center;
    text-transform: uppercase;
  }

  .print-layout-body {
    display: grid;
    grid-column-gap: $layout-spacing;
    grid-row-gap: $layout-spacing;
    grid-template-columns: 1fr;
  }

  .print-layout-main {
    .report-compact {
      grid-template-columns: 3fr 1fr;
    }
    .report-text {
      display: grid;
      grid-row-gap: $layout-spacing-sm;
      .print-layout-el:nth-child(5n+1) {
        margin-left: $layout-spacing;
      }
      .print-layout-el:nth-child(5n) {
        margin-right: $layout-spacing-xl;
        margin-bottom: $layout-spacing-sm;
      }
      .print-layout-el:last-child {
        margin-bottom: 0;
      }
    }
    .report-images {
      display: grid;
      grid-row-gap: $layout-spacing-sm;
    }
  }

  .print-layout-images {
    .images-col-1 {
      grid-template-columns: 1fr;
    }
    .images-col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    .images-col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    .images-col-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
