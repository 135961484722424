<template>
  <div class="mr-content">
    <template v-if="!isExternal">
      <div class="btn-group content-actions" v-if="hasMeasures">
        <button
          class="btn btn-info-outline btn-icon btn-icon-left"
          :class="{ 'btn-info': mode === 'measures' }"
          @click="mode = 'measures'"
        ><fa-icon :icon="['fal', 'monitor-heart-rate']"></fa-icon> Medidas</button>
        <button
          class="btn btn-info-outline btn-icon btn-icon-left"
          :class="{ 'btn-info': mode === 'report' }"
          @click="mode = 'report'"
        ><fa-icon :icon="['fal', 'line-height']"></fa-icon> Laudo</button>
      </div>

      <div v-if="hasMeasures" v-show="mode === 'measures'">
        <form class="form-horizontal measures">
          <div v-for="measure in measures" :key="measure.id" class="measure-group">
            <div class="measure-group-title">{{ measure.group }}</div>
            <div v-for="input in measure.inputs" class="form-group" :key="input.id">
              <div class="col-5">
                <label :for="input.id" class="form-label">{{ input.label }}</label>
              </div>
              <div class="col-3 input-group">
                <template v-if="input.type === 'formula'">
                  <dx-input-number
                    :value="input.value"
                    class="form-input text-right"
                    v-bind="input.params"
                    :disabled="!canEdit"
                    readonly="readonly"
                  />
                </template>
                <template v-else>
                  <dx-input-number
                    v-model="input.value"
                    class="form-input text-right"
                    v-bind="input.params"
                    @change="calculateFormulas"
                  />
                </template>
                <span class="input-group-addon">{{ input.unit }}</span>
              </div>
              <div class="col-4">
                <span class="ml-2">{{ input.params.info }}</span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div v-if="hasInputs" v-show="mode === 'report'">
        <question
          v-for="input in inputs"
          v-model="input.model"
          :type="input.type"
          :label="input.label"
          :options="input.options"
          :params="input.params"
          :readonly="!canEdit"
          @input="save"
          :key="input.id"
        />
      </div>
    </template>

    <!-- EXTERNAL MEDICAL REPORT -->
    <div class="empty" v-else>
      <div class="empty-icon">
        <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
      </div>
      <p class="empty-title h5">Laudo externo</p>
      <p class="empty-subtitle text-info">
        Este laudo foi importado de um programa externo e está disponível apenas para leitura.
      </p>
      <div class="empty-action">
        <a
          :href="data.externalReport"
          class="btn btn-info"
          target="_blank"
        >Visualizar Laudo</a>
      </div>
    </div>
  </div>
</template>

<script>
import form from '@/mixins/form';
import { Parser as ExprParser } from 'expr-eval';
import Question from '@/app/medical-record/components/Question.vue';

export default {
  mixins: [form],
  props: {
    data: {
      templateId: {
        type: String,
      },
      inputs: {
        type: Array,
      },
      measures: {
        type: Array,
      },
      externalReport: {
        type: String,
      },
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Question,
  },
  data() {
    return {
      mode: 'report',
      templateId: null,
      inputs: [],
      measures: [],
      timeoutId: null,
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    'data.templateId': function dataTemplateId() {
      this.updateData();
    },
  },
  computed: {
    hasMeasures() {
      return this.measures && this.measures.length > 0;
    },
    hasInputs() {
      return this.inputs && this.inputs.length > 0;
    },
    measureInputs() {
      return this.hasMeasures
        ? this.measures.reduce((result, { inputs }) => ([
          ...result, ...inputs.filter(({ type }) => type !== 'formula'),
        ]), [])
        : [];
    },
    measureInputValues() {
      return this.measureInputs.reduce((result, { id, value }) => {
        result[id] = value;
        return result;
      }, {});
    },
  },
  methods: {
    updateData() {
      const { templateId, inputs, measures } = this.data;

      this.templateId = templateId;

      this.inputs = Array.isArray(inputs)
        ? this.clone(inputs).map(item => ({
          ...item,
          model: {
            value: item.value || null,
            observation: item.observation || '',
          },
        }))
        : [];

      this.measures = Array.isArray(measures)
        ? this.clone(measures).map((group) => {
          group.inputs = group.inputs.map((input) => {
            input.value = input.value || 0;
            return input;
          });
          return group;
        })
        : [];

      const hasMeasuresToFill = (
        this.measureInputs.length > 0 && this.measureInputs.some(({ value }) => !value)
      );
      this.mode = hasMeasuresToFill ? 'measures' : 'report';
    },
    calculateFormulas() {
      this.save();
      this.measures.forEach((group) => {
        group.inputs.forEach((input) => {
          if (input.type === 'formula') {
            try {
              const formula = input.params.formula
                .replace(/{(.+?)}/g, (a, b) => (this.measureInputValues[b] || 0));
              input.value = ExprParser.evaluate(formula, this.measureInputValues) || 0;
              if (input.value === Infinity) {
                input.value = 0;
              }
            } catch (e) {
              input.value = 0;
            }
          }
        });
      });
    },
    save() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.$emit('save', {
          templateId: this.templateId,
          inputs: this.inputs,
          measures: this.measures,
        });
      }, 600);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.mr-content {
  .measure-group {
    margin-bottom: $layout-spacing-lg;
    .measure-group-title {
      border-bottom: $border-width solid $border-color;
      font-weight: 600;
      padding-bottom: $layout-spacing-sm;
      margin-bottom: $layout-spacing;
      text-transform: uppercase;
    }
  }
  .measure-group:last-child {
    margin-bottom: 0;
  }
}
</style>
